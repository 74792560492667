<template>
  <BasicBlock>
    <template v-if="title" v-slot:title>{{ title }}</template>
    <HorizontalMenu v-if="menu_items?.length" :hide-on-mobile="false" :items="menu_items"/>
    <CollectionsPreview :no-carousel="noCarousel" :collections="collections"/>
  </BasicBlock>
</template>

<script>
import BasicBlock from "@/components/molecules/BasicBlock.vue";
import CollectionsPreview from "@/components/organisms/CollectionsPreview.vue";
import HorizontalMenu from "@/components/molecules/HorizontalMenu.vue";

export default {
  props: {
    collections: {
      type: Array,
      required: true
    },
    menu_items: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    noCarousel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BasicBlock, CollectionsPreview, HorizontalMenu
  }
}
</script>

<style scoped>

</style>
